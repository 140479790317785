<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">审核管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" >课程审核</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">审核详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <div class="framePage-scroll"> -->
        <div class="ovy-a">
          <div class="flexcb">
            <h3 style="padding-bottom:20px;">课程基本信息</h3>
            <el-button
              v-show="active == 'second'"
              size="mini"
              class="bgc-bv"
              @click="doGoBack"
              >返回</el-button
            >
          </div>
          <div>
            <el-form ref="ruleForm" label-width="10rem" class="courseBox">
              <el-form-item label="课程名称：" class="form-cell">
                <p>{{ ruleForm.courseName }}</p>
              </el-form-item>
              <el-form-item
                label="课程分类"
                class="form-cell"
              >
                <p>{{ ruleForm.courseTypeName || "" }}</p>
              </el-form-item>
            
            </el-form>
            <el-form ref="ruleForm" label-width="10rem">
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div
                  v-html="ruleForm.context"
                  ref="editor"
                  style="width:100%;position: relative;z-index: 2;"
                />
              </el-form-item>
              <el-form-item label="课程图片：" prop="thumbnail">
                <div class="img-el-upload" style="width:256px;">
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                  ></el-image>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <h3 style="padding-bottom:20px;">课程章节</h3>
          <el-tree
            ref="tree"
            :data="data"
            node-key="courseKpointId"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @check-change="getChecked"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <template v-if="data.level == 1">
                <p>{{ data.catalogName }}</p>
              </template>
              <template v-else>
                <span
                  v-if="data.list.length > 0"
                  style="display: flex;flex-direction: row;align-items: center;"
                >
                  <i class="courseIcon"></i>
                  <!-- <i class="paperIcon" v-else-if="data.list[0].paperId"></i> -->
                </span>
                <span
                  :title="data.catalogName"
                  class="textellipsis"
                  v-text="data.catalogName"
                ></span>
                <el-button
                  type="text"
                  @click="showVideo(data)"
                  size="mini"
                  style="margin:0 1rem"
                  >预览</el-button
                >
                <!-- <el-radio-group
                  v-show="!data.hidden"
                  v-model="data.auditStates"
                  class="set-food-select"
                  size="mini"
                  value-key="specId"
                  @change="change(data)"
                >
                  <el-radio label="30">通过</el-radio>
                  <el-radio label="40">不通过</el-radio>
                </el-radio-group>
                <div class="limitput">
                  <el-input
                    v-model="data.reason"
                    size="small"
                    v-show="data.datainput == true"
                    style="margin=left:10px"
                    maxlength="50"
                    show-word-limit
                  />
                </div> -->
              </template>
            </span>
          </el-tree>
          <div>
            <span v-show="active !== 'second'" style="padding-right:50px"
              >课程：{{ ruleForm.courseName }}</span
            >
            <el-radio-group
              v-model="radio"
              @change="RadioChange"
              v-show="active != 'second'"
            >
              <el-radio label="30">通过</el-radio>
              <el-radio label="40">不通过</el-radio>
            </el-radio-group>
            <div style="display:flex;padding-top:.5rem;" v-show="radioShow">
              <el-input
                type="textarea"
                placeholder="请输入不通过原因"
                v-model="textarea"
                ref="textarea"
                maxlength="100"
                show-word-limit
              />
            </div>
          </div>
          <div
            style="display: flex; justify-content: center; padding:20px"
            v-show="active != 'second'"
          >
            <el-button @click="back()" class="bgc-bv">取消</el-button>
            <el-button @click="getCheckedNodes" class="bgc-bv">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let id = 1000;
import List from "@/mixins/List";
export default {
  name:'CourseReviewDetails',
  components: {
  },
  mixins: [List],
  
  data() {
    return {
      dialogVisible: false,
      src: "",
      teacherName: "",
      data: [],
      defaultProps: {
        id: "courseKpointId",
        children: "children",
        label: "courseKpointId"
      },
      ruleForm: {},
      radio: "",
      textarea: "",
      courseId: "",
      radioShow: false,
      active: this.$route.query.active,
      stu: ""
    };
  },
  created() {
    this.courseId = this.$route.query.courseId;
    // this.stu = this.$route.query.stu;
    this.getDetail(this.courseId);
  },
  methods: {
    doGoBack() {
      this.$router.push({
        path: "/web/miniApp/curriculumToExamine",
        query: {
          activeName: "second"
        }
      });
    },
    getDetail(courseId) {
      this.$post("/minicourse/getCourseInfo", {
        courseId
      },3000,true,2).then(ret => {
          let data = ret.data
          let miniCourse = ret.data.miniCourse;
        let miniCourseNodes = ret.data.miniCourseNodes;
        let chapter = ret.data.chapter;
        this.isChapter = ret.data.chapter;
        this.deletedChapter = ret.data.deletedChapter || [];
        this.ruleForm = {
          ...this.ruleForm,
          courseName: miniCourse.courseName, //课程名称
          courseTypeName: data.courseTypeName, // 课程分类
           thumbnailId: miniCourse.logo,
              salesState: miniCourse.salesState,
              thumbnail: ret.data.courseCoverUrl,
               context: miniCourse.context,
               createTime:miniCourse.createTime,
               auditState:miniCourse.auditState
        };
        this.radio = miniCourse.auditState;
          this.textarea = miniCourse.auditReason;
          if (this.radio == "40") {
            this.radioShow = true;
          } else {
            this.radioShow = false;
          }
        this.disabled = false;
         this.creatData(data);
      });
    },
    creatData(data = {}) {
      let list = [];
      this.chapterSchema = data.chapter;
      if (this.chapterSchema) {
          console.log('DDD');
        // 样式一 ------> 三层
        data.miniCourseNodes.forEach(element => {
          let nodeList = [];
          list.push({
            courseKpointId: element.videoId,
            catalogName: element.catalogName,
            parentNodeId: element.parentNodeId,
            courseOutlineId: element.courseOutlineId,
            children: nodeList,
            level: 1,
          });
          (element.children || []).forEach(obj => {
            nodeList.push(this.created2dom(obj));
          });
        });
        // console.log(nodeList);
      } else {
        data.miniCourseNodes.forEach(element => {
          list.push(this.created2dom(element));
        });
      }
      this.data = list;
    },
    created2dom(obj) {
        console.log('obj',obj);
      let children = [];
      // if (obj.paperId || obj.teacherPathName) {
      // }
      let listArr = [];
      if (obj.videoId) {
        obj.list = listArr;
        listArr.push({
          videoId: obj.videoId,
          courseOutlineId: obj.courseOutlineId
        });
      } 
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          catalogName: obj.catalogName,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: children,
          auditState: obj.auditState,
          auditStates: obj.auditState,
          section: obj.section,
          courseKpointId: obj.videoId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          reason: obj.auditState == "40" ? obj.auditReason : "",
          datainput: obj.auditState == "40" ? true : false,
          hidden: obj.hidden
        };
      } else {
        return {
          catalogName: obj.catalogName,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: [],
          auditState: obj.auditState,
          auditStates: obj.auditState||"30",
          section: obj.section,
          courseKpointId: obj.videoId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.videoId,
          kpointId: obj.kpointId,
          reason: obj.auditState == "40" ? obj.auditReason : "",
          datainput: obj.auditState == "40" ? true : false,
          // hidden: obj.hidden,
           hidden: true,
          paperId: obj.paperId,
          paperName: obj.paperName
        };
      }
    },
    change(data) {
      if (data.auditStates == "30") {
        data.datainput = false;
      } else {
        data.datainput = true;
      }
    },
    RadioChange(val) {
      if (val == "40") {
        this.radioShow = true;
      } else {
        this.radioShow = false;
      }
    },
    show(data) {
      this.dialogVisible = true;
      this.teacherName = data.catalogName;
      this.src = data.qualificationImageId;
    },
    /**
     * 保存
     */
    getCheckedNodes() {
    //   let courseWareList = [];
      let stu = true;
    //   this.data.forEach(item => {
    //     if (this.chapterSchema) {
    //       item.children.forEach(items => {
    //         if (items.auditStates == "40") {
    //           if (items.reason == "") {
    //             this.$message({
    //               message: "请输入课件不通过原因",
    //               type: "warning"
    //             });
    //             stu = false;
    //             return;
    //           }
    //         }
    //         courseWareList.push({
    //           kpointId: items.kpointId,
    //           paperId:items.paperId,
    //           auditState: items.auditStates,
    //           auditReason: items.auditStates == "40" ? items.reason : "",
    //           currentAuditState: items.auditState
    //         });
    //       });
    //     } else {
    //       courseWareList.push({
    //         kpointId: item.kpointId,
    //          paperId:item.paperId,
    //         auditState: item.auditStates,
    //         auditReason: item.auditStates == "40" ? item.reason : "",
    //         currentAuditState: item.auditState
    //       });
    //     }
    //   });
      if (this.radio == "40") {
        if (!this.textarea) {
          this.$message({
            message: "请输入课程不通过原因",
            type: "warning"
          });
          return false;
        }
        if (this.textarea) {
          if (this.$refs.textarea.value.length < 5) {
            this.$message({
              message: "请输入不少于5个字的课程不通过原因",
              type: "warning"
            });
            return false;
          }
        }
      }

      let parmar = {
        courseId: this.courseId,
        auditReason: this.textarea,
        currentAuditState: this.ruleForm.auditState,
        auditState: this.radio
      };
      if (!stu) {
        return;
      }
      this.$post("/run/minicourse/auditCourse", parmar,3000,true,2)
        .then(ret => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success"
            });
            setTimeout(() => {
              this.$router.push({
                path: "/web/miniApp/curriculumToExamine",
                query: {
                  activeName: this.active,
                  refresh: true
                }
              });
            }, 300);
          }
        })
        .catch(err => {
          return;
        });
    },
    back() {
      this.$router.push({
         path: "/web/miniApp/curriculumToExamine",
        query: {
          activeName: this.active
        }
      });
    },
    showVideo(data) {
      console.log(data);
       //单元测试打开是题库
       if (data.courseKpointId) {
        this.$store.dispatch("handleVideoUrl", {
        kpointVideoId: data.courseKpointId,
        kpointName: data.catalogName,
      });
      }
    }
  }
};
</script>
<style lang="less">
.courseBox {
  display: flex;
  flex-wrap: wrap;
  .form-cell {
    width: 50%;
  }
}
.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}
.form-inline {
  width: 40%;
}
.custom-tree-node {
  display: flex;
  align-items: center;
  padding: 5px 0;
  .el-input {
    margin-left: 15px;
  }
}
.el-tree-node__content {
  height: auto;
}
.textellipsis {
  width: 30rem;
  /* overflow: auto; */
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}
.el-textarea {
  height: 100px;
  .el-textarea__inner {
    height: 100px !important;
    min-height: 100px !important;
    resize: none;
  }
}
.limitput {
  .el-input__suffix {
    top: 15px;
  }
}
.el-image {
  display: block;
}

.imgBoxs {
  display: flex;
  > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
</style>
<style lang="less" scoped>
.el-textarea {
  .el-textarea__inner {
    height: 10rem !important;
    min-height: 200px !important;
  }
}
.Basicinformation {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    padding: 10px 0;
  }
  .information {
    display: flex;
    flex-wrap: wrap;
    .information-item {
      width: 50%;
    }
  }
}
.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node {
  .el-form-item {
    margin-bottom: 0px;
  }
}
.qualificationImageId {
  .el-image {
    display: flex;
    height: 500px;
  }
}
.dataqualificationImageId {
  margin:0 1rem;
}
.imgUpload {
  display: flex;
  justify-content: center;
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
</style>
